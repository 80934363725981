import { useCallback, useState, useEffect } from 'react';
import union from 'lodash/union';
import usePrevious from './use-previous';

import { getDefaultValidation } from './field-validations';

import * as c from './form-constants';

export default function useForm(fieldConfig, options = {}) {
  const [fields, setFields] = useState(createFields(fieldConfig));
  const [formIsValid, setFormIsValid] = useState(false);
  const [validationTimeout, setValidationTimeout] = useState(null);
  const prevFields = usePrevious(fields);

  if (options.formIsValid) setFormIsValid(options.formIsValid);

  function getValidationType(validation) {
    return Array.isArray(validation) ? 'array' : typeof validation;
  }

  function createField(field) {
    const fieldState = { ...field };

    if (field.defaultValue && !field.value)
      fieldState.value = field.defaultValue;

    fieldState.validations = configureFieldValidations(field.validations);

    return {
      ...c.defaultFieldState,
      ...(options?.fieldDefaults || {}),
      ...fieldState
    };
  }

  function createFields(fieldConfig) {
    return fieldConfig.map(createField);
  }

  function configureFieldValidations(validations) {
    const validationsType = getValidationType(validations);

    if (validationsType === 'string')
      return [getDefaultValidation(validations)];

    if (validationsType === 'function') return [validations];

    if (validationsType === 'array') {
      const fieldValidations = validations.map(validation => {
        if (typeof validation === 'string') {
          return getDefaultValidation(validation);
        } else {
          return validation;
        }
      });

      return fieldValidations;
    }
  }

  const getFields = useCallback(() => [...fields], [fields]);

  function getFieldValues() {
    const fieldValues = fields.reduce((acc, field) => {
      const value = field?.value?.value ? field.value.value : field.value;
      acc[field.name] = value;
      return acc;
    }, {});
    return fieldValues;
  }

  const getFieldsObject = useCallback(() => {
    return getFields().reduce((acc, field) => {
      acc[field.name] = field;
      return acc;
    }, {});
  }, [getFields]);

  const getFieldByName = useCallback(
    name => {
      return { ...fields.find(field => field.name === name) };
    },
    [fields]
  );

  const getFieldIndexByName = useCallback(
    name => {
      return fields.findIndex(field => field.name === name);
    },
    [fields]
  );

  const updateField = useCallback(
    (fieldName, value) => {
      const field = getFieldByName(fieldName);
      const index = getFieldIndexByName(fieldName);

      if (!value && field.condition === c.PRISTINE) return;

      field.value = field?.sanitize ? field.sanitize(value) : value;
      field.condition = c.DIRTY;

      setFields(current => {
        const newFields = [...current];
        newFields[index] = { ...current[index], ...field };
        return newFields;
      });
    },
    [getFieldByName, getFieldIndexByName, setFields]
  );

  const updateFieldConfig = useCallback(
    (fieldName, config) => {
      const index = getFieldIndexByName(fieldName);

      setFields(current => {
        const newFields = [...current];
        newFields[index] = { ...current[index], ...config };
        return newFields;
      });
    },
    [getFieldIndexByName, setFields]
  );

  const validateField = useCallback(
    async (fieldName, options = { validatePristine: false }) => {
      const field = getFieldByName(fieldName);
      const index = getFieldIndexByName(fieldName);

      clearTimeout(validationTimeout);

      if (
        !field.value &&
        field.condition === c.PRISTINE &&
        !options.validatePristine
      ) {
        field.error = c.defaultErrorState;
      } else {
        const validations = await Promise.all(
          field.validations.map(validation =>
            validation(
              field.value,
              { isRequired: field.isRequired },
              {
                getFields,
                getFieldsObject,
                updateField
              }
            )
          )
        );
        const errorState = validations
          .filter(err => err.hasErrors)
          .reduce(
            (acc, error) => {
              acc.hasErrors = true;
              acc.failedValidations = union(
                acc.failedValidations,
                error.failedValidations
              );
              acc.message = error.message;

              return acc;
            },
            { ...c.defaultErrorState }
          );

        field.error = errorState ? errorState : c.defaultErrorState;
      }

      setFields(current => {
        const newFields = [...current];
        newFields[index] = { ...current[index], ...field };
        return newFields;
      });

      if (typeof field.onValidateComplete === 'function')
        field.onValidateComplete({ ...field });
    },
    [
      getFieldByName,
      getFieldIndexByName,
      getFields,
      getFieldsObject,
      updateField,
      validationTimeout
    ]
  );

  function validateAll() {
    fields.forEach(field =>
      validateField(field.name, { validatePristine: true })
    );
  }

  function resetAll() {
    setFields(createFields(fieldConfig));
  }

  function updateFormIsValid() {
    const invalidFields = fields.filter(
      field => field.error.hasErrors || (field.isRequired && !field.value)
    );
    const isValid = invalidFields.length < 1;
    setFormIsValid(isValid);
  }

  function getChangedFields(fields = [], prevFields = []) {
    if (prevFields.length < 1) return [];

    const changedFields = fields.filter(field => {
      const prevField = prevFields.filter(prev => prev.name === field.name)[0];

      return field.value !== prevField.value;
    });

    return changedFields;
  }

  useEffect(() => {
    const changedFields = getChangedFields(fields, prevFields);

    if (changedFields.length) {
      changedFields.forEach(field => {
        if (field.validateOnChange || options?.validateOnChange)
          validateField(field.name);

        if (field.validateWithTimeout || options?.validateWithTimeout) {
          field.error = {
            ...field.error,
            isPendingValidation: true
          };
          clearTimeout(validationTimeout);
          setValidationTimeout(
            setTimeout(() => {
              validateField(field.name);
            }, field.delayTime)
          );
        }
      });
    }
  }, [fields, options, prevFields, validateField, validationTimeout]);

  useEffect(() => {
    updateFormIsValid();
  });

  return {
    fields: { ...getFieldsObject() },
    getFields,
    getFieldValues,
    updateField,
    validateField,
    validateAll,
    formIsValid,
    updateFieldConfig,
    resetAll
  };
}
