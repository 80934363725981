import has from 'lodash/has';

import { defaultErrorState } from './form-constants';
import { createError } from './form-utils';
import * as v from '../../shared/utils/validators';
import * as c from './form-constants';

export function validateBasicField(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = '', isRequired = true } = options;

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

function validateName(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.NAME, isRequired = true } = options;
  if (!v.isValidName(value)) {
    errorState = createError(
      c.IS_VALID_NAME,
      fieldName,
      errorState.failedValidations
    );
  }

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validateFirstName(value = '', options = {}) {
  return validateName(value, { fieldName: c.FIRST_NAME, ...options });
}

export function validateLastName(value = '', options = {}) {
  return validateName(value, { fieldName: c.LAST_NAME, ...options });
}

export function validateEmail(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.EMAIL, isRequired = true } = options;

  if (value && !v.isValidEmail(value)) {
    errorState = createError(
      c.IS_VALID_EMAIL,
      fieldName,
      errorState.failedValidations
    );
  }
  if (!v.emailContainsAt(value) && v.emailContainsTLD(value)) {
    errorState = createError(
      c.REQUIRE_AT,
      fieldName,
      errorState.failedValidations
    );
  }
  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validatePhone(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.PHONE, isRequired = true } = options;

  if (!v.isValidPhone(value)) {
    errorState = createError(
      c.IS_VALID_PHONE,
      fieldName,
      errorState.failedValidations
    );
  }

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validateSelect(value = {}, options = {}) {
  const fieldValue = has(value, 'value') ? value.value : '';

  return validateBasicField(fieldValue, options);
}

export function validatePhoneType(value = {}, options = {}) {
  return validateSelect(value, { fieldName: c.PHONE_TYPE, ...options });
}

export function validateCountry(value = {}, options = {}) {
  return validateSelect(value, { fieldName: c.COUNTRY, ...options });
}

export function validateState(value = {}, options = {}) {
  return validateSelect(value, { fieldName: c.STATE, ...options });
}

function validateAddress(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.ADDRESS, isRequired = true } = options;

  if (!v.maxLength(value, c.MAX_CHARACTER_LENGTH)) {
    errorState = createError(
      c.MAX_LENGTH,
      fieldName,
      errorState.failedValidations
    );
  }

  if (value.length > 0) {
    if (!v.atLeastOneNumeric(value)) {
      errorState = createError(
        c.AT_LEAST_ONE_NUMERIC,
        fieldName,
        errorState.failedValidations
      );
    }

    if (!v.isValidAddress(value)) {
      errorState = createError(
        c.IS_VALID_ADDRESS,
        fieldName,
        errorState.failedValidations
      );
    }
  }

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validateAddressLine1(value = '', options = {}) {
  return validateAddress(value, { fieldName: c.ADDRESS_LINE_1, ...options });
}

export function validateAddressLine2(value = '', options = {}) {
  return validateAddress(value, { fieldName: c.ADDRESS_LINE_2, ...options });
}

export function validateZip(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.ZIP, isRequired = true } = options;

  if (!v.isValidPostalCode(value) && v.notEmpty(value)) {
    errorState = createError(
      c.IS_VALID_POSTAL_CODE,
      fieldName,
      errorState.failedValidations
    );
  }

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validateCity(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.CITY, isRequired = true } = options;

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function validatePassword(value = '', options = {}) {
  let errorState = { ...defaultErrorState };
  const { fieldName = c.PASSWORD, isRequired = true } = options;

  if (
    !v.minLength(value, c.MIN_PASSWORD_LENGTH) ||
    !v.maxLength(value, c.MAX_PASSWORD_LENGTH) ||
    !v.atLeastOneNumeric(value) ||
    !v.atLeastOneAplha(value)
  ) {
    errorState = createError(
      c.IS_VALID_PASSWORD,
      fieldName,
      errorState.failedValidations
    );
  }

  if (!v.notEmpty(value) && isRequired) {
    errorState = createError(
      c.NOT_EMPTY,
      fieldName,
      errorState.failedValidations
    );
  }

  return errorState;
}

export function getDefaultValidation(type) {
  switch (type) {
    case c.TEXT:
      return validateBasicField;
    case c.FIRST_NAME:
      return validateFirstName;
    case c.LAST_NAME:
      return validateLastName;
    case c.EMAIL:
      return validateEmail;
    case c.PHONE:
      return validatePhone;
    case c.PHONE_TYPE:
      return validatePhoneType;
    case c.ADDRESS_LINE_1:
      return validateAddressLine1;
    case c.ADDRESS_LINE_2:
      return validateAddressLine2;
    case c.COUNTRY:
      return validateCountry;
    case c.ZIP:
      return validateZip;
    case c.CITY:
      return validateCity;
    case c.STATE:
      return validateState;
    case c.PASSWORD:
      return validatePassword;
    default:
      return validateBasicField;
  }
}

export default {
  validateBasicField,
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validatePhoneType,
  validateSelect,
  validateCountry,
  validateState,
  validateAddress,
  validateAddressLine1,
  validateAddressLine2,
  validateZip,
  validateCity,
  validatePassword
};
