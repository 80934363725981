import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';
import { CheckSolidIcon } from '../../shared/icons/icons';

function Checkbox(props) {
  const {
    children,
    className,
    id,
    label,
    name,
    onClick,
    value,
    isDisabled
  } = props;
  const [isChecked, setIsChecked] = useState(props.isChecked || false);
  const isCheckedVal =
    typeof props.isChecked === 'boolean' ? props.isChecked : isChecked;

  function handleClick(e) {
    e.preventDefault();
    if (onClick) {
      onClick(value, e);
    }

    if (typeof props.isChecked !== 'boolean') {
      setIsChecked(!isChecked);
    }
  }

  return (
    <div
      styleName={isDisabled ? 'checkbox-disabled' : 'checkbox'}
      className={`${className || ''} checkbox ${
        isCheckedVal ? 'checkbox--checked' : ''
      }`}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="none"
    >
      <span styleName="container">
        <input
          tabIndex="0"
          checked={isCheckedVal}
          id={id}
          name={name}
          type="checkbox"
          value={value}
          disabled={isDisabled}
        />
        <span styleName="display" className="checkbox__icon">
          <CheckSolidIcon />
        </span>
      </span>
      <label htmlFor={id}>{label}</label>
      {children}
    </div>
  );
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default Checkbox;
