import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/react-hooks';

import MinimalHeader from '../components/minimal-header/minimal-header';
import MinimalFooter from '../components/minimal-footer/minimal-footer';

import { GET_LAYOUT } from './graphql/minimal-layout-queries';

import './minimal-layout.scss';

function MinimalLayout(props) {
  const { children, onLogoClick } = props;
  const { data, error } = useQuery(GET_LAYOUT);
  const { cms } = data || {};
  const { globalFooter } = cms || {};

  if (error) {
    console.error('Error loading minimal layout query: ', error);
  }

  return (
    <>
      <MinimalHeader onLogoClick={onLogoClick} />
      <main id="main" styleName="main">
        {children}
      </main>
      <MinimalFooter links={globalFooter?.secondaryLinks} />
    </>
  );
}

MinimalLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  onLogoClick: PropTypes.func
};

export default MinimalLayout;
