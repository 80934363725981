import { gql } from 'apollo-boost';

export const GET_LAYOUT = gql`
  {
    cms {
      globalFooter {
        secondaryLinks {
          name
          url
        }
      }
    }
  }
`;
