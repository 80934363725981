import has from 'lodash/has';

import * as c from './form-constants';
import { defaultFormErrorMessages } from '../../shared/constants/messages';
import fieldTypes from './field-types';

export const createField = (config = {}, fieldType) => {
  const fieldTemplate = fieldType ? fieldTypes[fieldType] : {};

  return {
    ...c.defaultFieldState,
    ...fieldTemplate,
    ...config
  };
};

export const createError = (
  key,
  fieldName,
  failedValidations = [],
  message
) => {
  const errorState = { ...c.defaultErrorState };

  errorState.hasErrors = true;
  errorState.failedValidations = [...failedValidations, key];
  errorState.message = has(defaultFormErrorMessages, [fieldName, key])
    ? defaultFormErrorMessages[fieldName][key]
    : '';

  if (message) {
    errorState.message = message;
  }

  return errorState;
};

export default { createField, createError };
