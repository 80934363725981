import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import Text from '../../library/text/text';
import { fieldTypes } from '../../shared/hooks/use-form/use-form';
import { useForm } from '../../shared/hooks/hooks';

import Checkbox from '../../library/checkbox/checkbox';
import PhoneNumberInput from '../../library/phone-number-input/phone-number-input';
import Page from '../../library/page/page';
import Button, { ButtonGroup } from '../../library/button/button';

import fieldValidations from '../../library/form/field-validations';
import { validateBasicField } from '../../library/form/field-validations';
import MinimalLayout from '../../layouts/minimal-layout/minimal-layout';
import {
  EMAIL,
  formUtils,
  PHONE,
  QUOTE_NUMBER,
  TEXT
} from '../../library/form';

import './home-page.scss';
import InputGroup from '../../library/input-group/input-group';

function HomePage() {
  const formData = {
    storeCode: 'GAA 43',
    quoteNumber: '123456789',
    mobileNumber: '4806066000',
    emailAddress: 'quotetobopis@discounttire.com',
    createAccount: true,
    sendSMS: false,
    sendEmail: true
  };
  useCallback(() => {
    fetch(
      '/mocking/api/v1/sources/exchange/assets/23f3ea22-e8b2-41cd-b0f5-0a2288e80802/dt-pos-exp-api/1.0.23/m/quoteToBopis/notifications',
      {
        method: 'POST',

        headers: {
          Accept: 'application/json',
          client_id: '0oas2vck3r8qyKTNR0h7',
          client_secret: 'zTvnUgM6QgUBRHlFK8F5HebRfk093Veja4Tbdjrn',
          dtSourceSystem: 'Hybris',
          dtSourceSubsystem: 'az',
          dtRequestIdentifier: 'TW_RECOMMENDATION',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const { createField } = formUtils;
  const { fields, updateField, validateField } = useForm(
    [
      createField(
        {
          name: 'storeId',
          isRequired: true,
          value: ''
        },
        TEXT
      ),
      createField(
        {
          name: 'quoteNumber',
          isRequired: true,
          value: ''
        },
        QUOTE_NUMBER
      ),
      createField(
        {
          name: 'email',
          isRequired: true,
          value: ''
        },
        EMAIL
      ),
      createField(
        {
          name: 'phone',
          isRequired: true,
          value: ''
        },
        PHONE
      )
    ],
    {
      validateWithTimeout: true
    }
  );

  const { storeId, quoteNumber, email, phone } = fields;

  function handleOnClick() {
    console.log(fields);
  }

  return (
    <MinimalLayout>
      <Page styleName="home-page-wrapper">
        <img
          styleName="lets_img"
          src="images/lets.jpeg"
          alt="Lets get you taken care of"
        />
        <div className="form-container">
          <form styleName="register-form">
            <InputGroup columnCount={1}>
              <Text
                label="Store ID"
                isRequired={storeId.isRequired}
                name={storeId.name}
                value={storeId.value}
                onChange={e => updateField(storeId.name, e.target.value)}
                onBlur={() => validateField(storeId.name)}
              />
              <Text
                label="Quote Number"
                isRequired={quoteNumber.isRequired}
                name={quoteNumber.name}
                value={quoteNumber.value}
                onChange={e => updateField(quoteNumber.name, e.target.value)}
                onBlur={() => validateField(quoteNumber.name)}
              />
              <Text
                label="Email Address"
                name={email.name}
                value={email.value}
                onChange={e => updateField(email.name, e.target.value)}
                onBlur={() => validateField(email.name)}
              />
              <PhoneNumberInput
                label="Phone Number"
                name={phone.name}
                value={phone.value}
                onChange={e => updateField(phone.name, e.target.value)}
                onBlur={() => validateField(phone.name)}
              ></PhoneNumberInput>

              <Checkbox
                id="send-sms-checkbox"
                label="Send Text Message With Quote Information"
                onClick={handleOnClick}
              />

              <Checkbox
                id="send-email-checkbox"
                label="Send Email With Quote Information"
                onClick={handleOnClick}
              />
            </InputGroup>
            <Button styleName="submit_button" onClick={handleOnClick} isPrimary>
              Notify Customer
            </Button>
          </form>
        </div>
      </Page>
    </MinimalLayout>
  );
}

export default hot(HomePage);
