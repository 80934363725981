import { useApolloClient } from '@apollo/react-hooks';

import useForm from '../../../library/form';

export default function useFormWrapper(fieldConfig, options = {}) {
  const client = useApolloClient();

  function addClientToValidation(validation) {
    if (typeof validation === 'function') {
      return (value, options, formMethods) =>
        validation(value, options, formMethods, client);
    }
  }

  const modifiedFieldConfig = fieldConfig.map(field => {
    if (typeof field?.validations === 'function') {
      return {
        ...field,
        validations: addClientToValidation(field.validations)
      };
    }

    if (Array.isArray(field?.validations)) {
      return {
        ...field,
        validations: field.validations.map(addClientToValidation)
      };
    }

    return field;
  });

  return useForm(modifiedFieldConfig, options);
}

export * from '../../../library/form/form-constants';
export { default as fieldTypes } from './field-types';
export { default as fieldValidations } from './field-validations';
export { default as formUtils } from '../../../library/form/form-utils';
