import React from 'react';
import PropTypes from 'prop-types';

import './label.scss';

const Label = props => {
  const { className, label, htmlFor = '', isRequired, minimize } = props;

  return (
    <label
      data-testid={`label-for-${htmlFor}`}
      htmlFor={htmlFor}
      styleName={`default ${minimize ? 'minimize' : ''}`}
      className={`label ${minimize ? 'label--minimize' : ''} ${className ||
        ''}`}
    >
      {isRequired && (
        <span styleName="required" aria-hidden="true">
          *&nbsp;
        </span>
      )}
      {label}
    </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  minimize: PropTypes.bool
};

export default Label;
