// Defaults

export const MAX_CHARACTER_LENGTH = 255;
export const MAX_PASSWORD_LENGTH = 40;
export const MIN_PASSWORD_LENGTH = 7;
const ON_CHANGE_TIMEOUT_DELAY = 3000;

// Default field types

export const TEXT = 'text';
export const SELECT = 'select';

export const NAME = 'name';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const PHONE_TYPE = 'phoneType';
export const ADDRESS = 'address';
export const ADDRESS_LINE_1 = 'addressLine1';
export const ADDRESS_LINE_2 = 'addressLine2';
export const COUNTRY = 'country';
export const ZIP = 'zip';
export const CITY = 'city';
export const STATE = 'state';
export const PASSWORD = 'password';
export const STORE_ID = 'storeId';
export const QUOTE_NUMBER = 'quoteNumber';

// Validation keys
export const REQUIRE_AT = 'requireAt';
export const NOT_EMPTY = 'notEmpty';
export const MAX_LENGTH = 'maxLength';
export const AT_LEAST_ONE_NUMERIC = 'atLeastOneNumeric';
export const IS_VALID_NAME = 'isValidName';
export const IS_VALID_EMAIL = 'isValidEmail';
export const IS_VALID_PHONE = 'isValidPhone';
export const IS_VALID_ADDRESS = 'isValidAddress';
export const IS_VALID_POSTAL_CODE = 'isValidPostalCode';
export const IS_VALID_PASSWORD = 'isValidPassword';

// Field condition

export const PRISTINE = 'pristine';
export const DIRTY = 'dirty';

// Default field states

export const defaultErrorState = {
  hasErrors: false,
  isPendingValidation: false,
  failedValidations: [],
  message: ''
};

export const defaultFieldState = {
  name: '',
  value: '',
  error: defaultErrorState,
  isRequired: true,
  validations: [],
  condition: PRISTINE,
  validateOnChange: false,
  validateWithTimeout: false,
  delayTime: ON_CHANGE_TIMEOUT_DELAY
};
