import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AngleDownLightIcon,
  AngleUpLightIcon
} from '../../../shared/icons/icons';
import FooterHeadline from '../footer-headline/footer-headline';

import './minimal-footer.scss';

function MinimalFooter({ links }) {
  const [isOpen, setIsOpen] = useState(false);
  const year = new Date().getFullYear();

  function toggleGroup() {
    setIsOpen(!isOpen);
  }

  return (
    <footer styleName="wrapper">
      <div styleName="container">
        <FooterHeadline />
        {links && (
          <div styleName="link-group">
            <button
              styleName="link-group-name"
              type="button"
              onClick={toggleGroup}
            >
              Policies
              {isOpen ? <AngleUpLightIcon /> : <AngleDownLightIcon />}
            </button>
            <ul
              styleName="list"
              style={{ maxHeight: isOpen ? links.length * 56 : 0 }}
            >
              {links.map((link, idx) => (
                <li key={`mfl-${idx}`} styleName="list-item">
                  <a
                    styleName="link"
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div styleName="copyright">
          © {year} The Reinalt-Thomas Corporation. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

MinimalFooter.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  )
};

export default MinimalFooter;
