import * as c from './form-constants';
import * as v from './field-validations';

// General Field Types
export const text = {
  validations: v.validateBasicField
};

export const select = {
  validations: v.validateSelect
};

// Specific Field Types

export const firstName = {
  name: c.FIRST_NAME,
  validations: v.validateFirstName
};

export const lastName = {
  name: c.LAST_NAME,
  validations: v.validateLastName
};

export const email = {
  name: c.EMAIL,
  validations: v.validateEmail,
  sanitize: val => val?.trim()
};

export const phone = {
  name: c.PHONE,
  validations: v.validatePhone
};

export const phoneType = {
  name: c.PHONE_TYPE,
  validations: v.validatePhoneType
};

export const addressLine1 = {
  name: c.ADDRESS_LINE_1,
  validations: v.validateAddressLine1
};

export const addressLine2 = {
  name: c.ADDRESS_LINE_2,
  isRequired: false,
  validations: v.validateAddressLine2
};

export const country = {
  name: c.COUNTRY,
  validations: v.validateCountry
};

export const zip = {
  name: c.ZIP,
  validations: v.validateZip
};

export const city = {
  name: c.CITY,
  validations: v.validateCity
};

export const state = {
  name: c.STATE,
  validations: v.validateState
};

export const password = {
  name: c.PASSWORD,
  validations: v.validatePassword
};

export default {
  text,
  firstName,
  lastName,
  email,
  phone,
  phoneType,
  addressLine1,
  addressLine2,
  country,
  zip,
  city,
  state,
  password
};
