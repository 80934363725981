export const notEmpty = val => /\S+/.test(val);

export const isArrayWithLength = val => Array.isArray(val) && val.length > 0;

export const isNill = val => val === null || val === void 0;

export const isNotEmptyNullOrUndefined = val =>
  val !== null && val !== '' && val !== undefined;

export const minLength = (val, min) => val.length >= min;

export const maxLength = (val, max) => val.length <= max;

export const emailContainsTLD = val => val && val.includes('.');

export const emailContainsAt = val => val && val.includes('@');

export const emailOmitsComma = val => val && !val.includes(',');

export const isValidEmail = val => {
  if (!emailContainsTLD(val)) return false;
  if (!emailContainsAt(val)) return false;
  if (!emailOmitsComma(val)) return false;
  if (!maxLength(val, 255)) return false;
  return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(val);
};

export const isValidName = val => {
  if (/^[\s]+/.test(val)) return false;
  return /^[-A-Za-z.'\s]+$/.test(val);
};

export const isValidPhone = val =>
  /^(?:[+][1-9][0-9]{0,1}\s?)?(?:(?:\([1-9][0-9]{2}\)\s?[1-9][0-9]{2}[-\s.]?[0-9]{4})|(?:[1-9][0-9]{2}([-\s.]?)[1-9][0-9]{2}\1?[0-9]{4}))$/.test(
    val
  );

export const isValidAddress = val => /.[a-zA-Z]+./.test(val);

export const isNumeric = val => /^\d+$/.test(val);

export const atLeastOneAplha = val => /[A-Za-z]{1}/.test(val);

export const atLeastOneNumeric = val => /.*\d.*/.test(val);

export const isLessThan = (val, max) => val < max;

export const isValid5DigitPostalCode = val => /^[0-9]{5}$/.test(val);

export const isValidPostalCode = val => {
  const normVal = typeof val === 'string' ? val.toUpperCase() : val;
  return (
    /* US zip code */
    /^\d{5}([-]\d{4})?$/.test(normVal) ||
    /* CA postal code */
    /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/.test(
      normVal
    )
  );
};

export const notEmptyObject = obj =>
  Object.keys(obj).length !== 0 && obj.constructor === Object;

export const hasUpperCase = val => /[A-Z]/.test(val);

export const hasLowerCase = val => /[a-z]/.test(val);

export const isValidSite = val =>
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
    val
  );

export const isValidPrice = val =>
  /^[-]?\$?(?:([0-9],?)+(?:\.[0-9]{0,2})?)?$/.test(val);
