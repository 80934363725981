import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ExclamationTriangleSolidIcon } from '../../shared/icons/icons';
import Label from './label/label';

import './input.scss';

const Input = props => {
  const {
    id,
    className = '',
    children,
    error,
    label,
    labelClassName,
    infoMessage,
    value,
    defaultValue,
    isRequired,
    isDisabled
  } = props;
  const showError = error && error.hasErrors && !error.isPendingValidation;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      styleName="default"
      className={`${className || ''} input`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      role="none"
    >
      <div
        styleName={`input-container${!showError && infoMessage ? '-info' : ''}${
          showError ? '-error' : ''
        } ${isDisabled ? 'input-container--disabled' : ''}`}
        className="input-container"
      >
        {label && (
          <Label
            styleName={`${isDisabled && !value ? 'input-label-disabled' : ''}`}
            className={labelClassName}
            data-testid={`input-label-for-${id}`}
            htmlFor={id}
            isRequired={isRequired}
            label={label}
            minimize={(value || defaultValue) !== undefined || isFocused}
          />
        )}
        {children}
      </div>
      <div styleName="messages" aria-live="assertive" aria-atomic="true">
        {showError && (
          <p styleName="error-message" className="input__error-message">
            <ExclamationTriangleSolidIcon styleName="icon" />
            {error.message}
          </p>
        )}
        {infoMessage && <p styleName="info-message">{infoMessage}</p>}
      </div>
    </div>
  );
};

Input.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object
  ]),
  error: PropTypes.shape({
    hasErrors: PropTypes.bool.isRequired,
    failedValidations: PropTypes.array,
    message: PropTypes.string
  }),
  id: PropTypes.string,
  infoMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ])
};

export default Input;
