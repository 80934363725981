import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Link from '../link/link';
import ButtonGroup from './components/button-group/button-group';

import './button.scss';

const Button = forwardRef(function Button(props, ref) {
  const {
    children,
    icon: Icon,
    isDisabled = false,
    isFullWidth = false,
    isPrimary = false,
    isSecondary = false,
    isTertiary = false,
    href,
    ...otherProps
  } = props;
  const defaultAriaLabel = typeof children === 'string' ? children : null;
  let button;
  let styleName = 'default';

  if (isPrimary) {
    styleName = 'primary';
  } else if (isSecondary) {
    styleName = 'secondary';
  } else if (isTertiary) {
    styleName = 'tertiary';
  }

  if (isFullWidth) {
    styleName = `${styleName} full-width`;
  }

  if (href) {
    button = (
      <Link
        styleName={styleName}
        className={`button button--${styleName}`}
        to={href}
        aria-label={props['aria-label'] || defaultAriaLabel}
        ref={ref}
        {...otherProps}
      >
        {Boolean(Icon) && <Icon styleName="icon" className="button__icon" />}
        {children}
      </Link>
    );
  } else {
    button = (
      <button
        styleName={styleName}
        className={`button button--${styleName}`}
        disabled={isDisabled}
        aria-label={props['aria-label'] || defaultAriaLabel}
        ref={ref}
        {...otherProps}
      >
        {Boolean(Icon) && <Icon styleName="icon" className="button__icon" />}
        {children}
      </button>
    );
  }

  return button;
});

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.func,
  isDisabled: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isTertiary: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  rel: PropTypes.string,
  target: PropTypes.string,
  tabIndex: PropTypes.number,
  isFullWidth: PropTypes.bool,
  'aria-label': PropTypes.string //TODO: Remove this prop and use ariaLabel everywhere.
};

export { ButtonGroup };
export default Button;
