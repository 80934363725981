import React from 'react';
import PropTypes from 'prop-types';

import Heading from './components/heading/heading';
import Content from './components/content/content';
import Section from './components/section/section';
import Breadcrumbs from './components/breadcrumbs/breadcrumbs';

function Page(props) {
  const { children, className } = props;

  return <div className={`${className || ''} page`}>{children}</div>;
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

export { Heading, Breadcrumbs, Content, Section };
export default Page;
