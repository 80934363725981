export const defaultFormErrorMessages = {
  firstName: {
    isValidName: 'First Name is invalid',
    notEmpty: 'First Name cannot be empty'
  },
  lastName: {
    isValidName: 'Last Name is invalid',
    notEmpty: 'Last Name cannot be empty'
  },
  email: {
    isValidEmail: 'Email is invalid',
    notEmpty: 'Email cannot be empty',
    requireAt: 'Email address is missing @ character'
  },
  phone: {
    isValidPhone: 'Phone number is invalid',
    notEmpty: 'Phone number cannot be empty'
  },
  phoneType: {
    notEmpty: 'Phone type cannot be empty'
  },
  addressLine1: {
    maxLength: 'Address Line 1 is too long',
    atLeastOneNumeric: 'Please enter your full street address',
    isValidAddress: 'Please enter your full street address',
    notEmpty: 'Address Line 1 cannot be empty'
  },
  addressLine2: {
    maxLength: 'Address Line 2 is too long',
    atLeastOneNumeric: 'Please enter your full street address',
    isValidAddress: 'Please enter your full street address',
    notEmpty: 'Address Line 2 cannot be empty'
  },
  zip: {
    isValidPostalCode: 'Zip Code is invalid',
    notEmpty: 'Zip Code cannot be empty'
  },
  city: {
    notEmpty: 'City cannot be empty'
  },
  state: {
    notEmpty: 'State cannot be empty'
  },
  country: {
    notEmpty: 'Country cannot be empty'
  },
  password: {
    hasLowerCase: 'Password must contain upper and lower case letters',
    hasUpperCase: 'Password must contain upper and lower case letters',
    maxLength: 'Password must be between 7 - 40 characters',
    minLength: 'Password must be between 7 - 40 characters',
    atLeastOneNumeric: 'Password must contain a number',
    notEmpty: 'Password cannot be empty',
    combinedError:
      'Passwords must include at least seven characters and at least one letter and one number',
    isValidPassword:
      'Passwords must include at least seven characters and at least one letter and one number'
  },
  link: {
    notEmpty: 'Link to lower price is required.',
    isValidLink: 'Link appears to be invalid.'
  },
  price: {
    notEmpty: 'Lower price is required.',
    isValidPrice: 'Price is invalid.'
  },
  companyName: {
    isValidName: 'Company Name is invalid',
    notEmpty: 'Company Name cannot be empty'
  },
  fleetAccountNumber: {
    isValidFleetCompany:
      'Fleet account number not found. Please check the account number and try again. For help with you fleet account call (866) 895-8032',
    isValidName: 'Fleet Account Number is invalid',
    notEmpty: 'Fleet Account Number cannot be empty'
  }
};

export const APPOINTMENT_DROPOFF_MESSAGE = 'APPOINTMENT_DROPOFF_MESSAGE';
export const APPOINTMENT_DROPOFF_STORE_CLOSE_MESSAGE =
  'APPOINTMENT_DROPOFF_STORE_CLOSE_MESSAGE';
export const AWD_BELOW_RECOMMENDED_QUANTITY_LONG_MSG =
  'AWD_BELOW_RECOMMENDED_QUANTITY_LONG_MSG';
export const AWD_BELOW_RECOMMENDED_QUANTITY_SHORT_MSG =
  'AWD_BELOW_RECOMMENDED_QUANTITY_SHORT_MSG';
export const PAYMENT_PLANS_AVAILABLE_MSG = 'PAYMENT_PLANS_AVAILABLE_MSG';
export const PAYMENT_PLANS_MIN_AMOUNT = 'PAYMENT_PLANS_MIN_AMOUNT';
export const CERTIFICATES_FULL_DESCRIPTION_MSG =
  'CERTIFICATES_FULL_DESCRIPTION_MSG';
export const CERTIFICATES_NO_CHECKBOX_MSG = 'CERTIFICATES_NO_CHECKBOX_MSG';
export const CERTIFICATES_YES_CHECKBOX_MSG = 'CERTIFICATES_YES_CHECKBOX_MSG';
export const CONFIRM_STORE_MODAL_SUBTITLE = 'CONFIRM_STORE_MODAL_SUBTITLE';

export default {
  'article.dtat.stockLevel.myStore': 'In stock at My Store',
  'article.dtat.stocklevel.nearbyStores': 'In stock at Nearby Stores',
  'article.dtat.stockLevel.moreThan': 'More than 20',
  'article.dtat.stockLevel.inStock': 'Available today within 35 miles',
  'article.dtat.stockLevel.checkoutFlow': 'In stock',
  'article.dtat.stockLevel.onOrder': 'Usually available within 1-2 days',
  'article.dtat.stockLevel.lowStock': 'Low stock',
  'article.dtat.stockLevel.outOfStock': '0 In Stock',
  'article.dtat.stockLevel.notAvailable': 'Not available',
  'article.dtat.stockLevel.specialOrderWCheckout':
    'Usually available within 3-5 days',
  'article.dtat.stockMessage.default': 'Order now, available in 3 - 5 days',
  'article.dtat.stockMessage.inSuffientStock': 'Order now, available in 2 days',
  'article.dtat.stockMessage.myStoreHasSufficientStock': 'Available today',
  'article.dtat.stockMessage.nearByStoresHaveSuffientStock':
    'Order now, available tomorrow',
  'article.dtat.stockMessage.notInStock': 'Order now, available in 3 - 5 days',
  'article.dtat.stockMessage.vendersHaveSuffientStock':
    'Order now, available tomorrow',
  'article.dtd.stockLevel.default': 'Call for assistance!',
  'article.dtd.stockLevel.inStock': 'In stock, over 12 are available.',
  'article.dtd.stockLevel.lowStock':
    'Low stock, Less than 12 are left - order soon.',
  'article.dtd.stockLevel.lowStockPercent': 'Low stock 30% Y',
  'article.dtd.stockLevel.notAvailable': 'NOT AVAILABLE',
  'article.dtd.stockLevel.onOrder': 'On Order',
  'article.dtd.stockLevel.outOfStock': 'Out of stock',
  'article.dtd.stockLevel.specialOrderWCheckout': 'Special order',
  'article.dtd.stockLevel.specialOrderWOCheckout':
    'Low stock. Call for availability.',
  'basket.error.quantity.invalid.binding':
    'Please enter a quantity between 1 and 99 to add this product to your cart.',
  'img.missingProductImage.tire.list':
    '/_ui/responsive/common/images/tire_noimage.png',
  'img.missingProductImage.wheel.list':
    '/_ui/responsive/common/images/wheel_noimage.png',
  'product.list.topthree.bestSeller':
    'Our customers purchase these tires most often.',
  'product.list.topthree.highestRated':
    'These tires have the most positive reviews from our customers.',
  'product.list.topthree.originalEquipment':
    'These are the tires your vehicle is equipped with from the factory.',
  'product.list.topthree.ourRecommendation':
    'We recommend these tires based on extensive performance and safety testing, and overall value.',
  'product.wheel.viewonvehicle': 'View on my vehicle ',
  'search.fitment.message.all.prompt.tires':
    'Your vehicle uses different tire sizes for front and rear tires. We recommend you shop by set if you wish to replace all 4.',
  'search.fitment.message.all.prompt.wheels':
    'Your vehicle uses different wheel sizes for front and rear wheels.'
};
