import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Text from '../text/text';
import NumberFormat from 'react-number-format';

// To respond to changes of the model value rather than the formatted value, provide
// an `onValueChange` prop
const PhoneNumberInput = forwardRef(function PhoneNumberInput(props, ref) {
  function prepareValue(value) {
    if (typeof value !== 'string') {
      return value;
    }

    // strip leading ones and non-numeric characters
    return value.replace(/^1+/, '').replace(/\D/g, '');
  }

  function formatValue(value) {
    if (typeof value !== 'string') {
      return value;
    }

    const areaCode = value.substr(0, 3);
    const secondThree = value.substr(3, 3);
    const lastFour = value.substr(6, 4);

    let format = '';

    if (areaCode) {
      format += `(${areaCode}`;
    }

    if (secondThree) {
      format += `) ${secondThree}`;
    }

    if (lastFour) {
      format += `-${lastFour}`;
    }

    return format;
  }

  return (
    <NumberFormat
      customInput={Text}
      format={formatValue}
      ref={ref}
      {...props}
      value={prepareValue(props.value)}
    />
  );
});

PhoneNumberInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onValueChange: PropTypes.func,
  pattern: PropTypes.string,
  type: PropTypes.string,
  tabIndex: PropTypes.string,
  value: PropTypes.string
};

export default PhoneNumberInput;
