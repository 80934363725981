import React from 'react';
import PropTypes from 'prop-types';

import './input-group.scss';

function InputGroup({ className, columnCount, children }) {
  const style = columnCount ? { width: `${100 / columnCount}%` } : null;

  return (
    <div
      styleName="container"
      className={`input-group ${className || ''}`}
      data-testid="input-group"
    >
      {React.Children.map(
        children,
        child =>
          child && (
            <div className="input-group__child" styleName="cell" style={style}>
              {child}
            </div>
          )
      )}
    </div>
  );
}

InputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  columnCount: PropTypes.number
};

export default InputGroup;
