import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Input from '../input/input';
import generateUID from '../../shared/utils/generate-uid';

import './text.scss';

const Text = forwardRef(function Text(props, ref) {
  const {
    children,
    value,
    isDisabled = false,
    id,
    isReadOnly = false,
    isRequired = false,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    name,
    type = 'text',
    tabIndex = '0',
    pattern
  } = props;
  const inputId = useMemo(() => id || generateUID(), [id]);

  return (
    <Input {...props} id={inputId}>
      <input
        styleName="text-input"
        aria-required={isRequired}
        type={type}
        id={inputId}
        data-testid={`${name}-input`}
        name={name}
        onBlur={onBlur || null}
        onFocus={onFocus || null}
        onChange={onChange || null}
        onKeyPress={onKeyPress || null}
        value={value}
        required={isRequired}
        disabled={isDisabled}
        readOnly={isReadOnly}
        tabIndex={tabIndex}
        pattern={pattern}
        ref={ref}
      />
      {children}
    </Input>
  );
});

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  type: PropTypes.string,
  tabIndex: PropTypes.string,
  value: PropTypes.string
};

export default Text;
