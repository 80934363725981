import React from 'react';

import './footer-headline.scss';

const FooterHeadline = () => (
  <h3 styleName="heading" className="footer-headline">
    <strong>Fast</strong> &amp; <strong>Friendly</strong> Service
    <span styleName="subheading">on every order, every day</span>
  </h3>
);

export default FooterHeadline;
